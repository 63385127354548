/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationsubject_module_name">
                    {{cvSubjectNameLabel}}</label
                  >
                  <select v-model="vmSubjectModuleFormData.interest_id" class="form-control"  style="text-transform: capitalize;">
                    <option  v-for="(subModName, index) of subjectModuleName" :key="(index+1)" :value="subModName" >
                      {{subModName.interest_name}}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationsubject_module_name">
                    {{cvSubjectModuleNameLabel}}</label
                  >
                  <input
                    v-model="vmSubjectModuleFormData.subject_module_name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="tprepSubjectModuleAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { TPrepSubjectModules } from "../../../FackApi/api/TPrepSubjectModule.js"
import ApiResponse from "../../../Utils/apiResponse"
import { Interests } from "../../../FackApi/api/interest"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"
// import { User } from "../../../FackApi/api/user"

export default {
  name: "TPrepSubjectModuleAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  data () {
    return {
      apiName: "tprep_subject_module_add",
      cvCardTitle: "Add TestSeries Module",
      cvCardSubHeader: "Add TestSeries Module ",
      cvSubmitBtn: "Add",
      cvSubjectNameLabel: "Subject/Exam Name",
      cvSubjectModuleNameLabel: "Module Name",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "TestSeries Response",
      vmSubjectModuleFormData: Object.assign({}, this.initFormData()),
      vmSubjectModuleDesc: null,
      vmSubjectModuleType: null,
      // InterestType: "SUBJ",
      subjectModuleName: [],
      subModName: ""
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    await this.interestList()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "interest_id": "",
        "subject_module_name": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSubjectModuleFormData) {
          if (!this.vmSubjectModuleFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    async interestList (interestType) {
      try {
        let interestObj = {
          "page": this.page
        }
        let interestListResp = await Interests.interestWithCategoryList(this, interestObj, interestType)
        if (interestListResp.resp_status) {
          this.interestObjList = interestListResp.resp_data
          this.subjectModuleName = this.interestObjList.INTERESTCAT11117
          this.interestCatObjList = interestListResp.resp_data_1
          for (let intCat in this.interestCatObjList) {
            this.interestCatLimit[this.interestCatObjList[intCat].interest_cat_id] = this.ITEM_LIMIT
          }
          this.totalRows = interestListResp.resp_data.count
        }
        else {
          this.toastMsg = interestListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at interestList() and Exception:", err.message)
      }
    },
    /**
     * tprepSubjectModuleAdd
     */
    async tprepSubjectModuleAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        this.vmSubjectModuleFormData.subject_module_name = this.vmSubjectModuleFormData.interest_id.interest_name + "-" + this.vmSubjectModuleFormData.subject_module_name
        this.vmSubjectModuleFormData.interest_id = this.vmSubjectModuleFormData.interest_id.interest_id

        let subjectModuleAddResp = await TPrepSubjectModules.tprepSubjectModuleAdd(this, this.vmSubjectModuleFormData)
        await ApiResponse.responseMessageDisplay(this, subjectModuleAddResp)
        if (subjectModuleAddResp && !subjectModuleAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSubjectModuleAddModal", subjectModuleAddResp.resp_data)
        }
        this.vmSubjectModuleFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at tprepSubjectModuleAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
